import React, { useState } from 'react'
import { Button, Modal, Row, ModalBody, Col, Alert } from 'reactstrap';
import { to } from 'await-to-js';
import axios from 'axios';
import { withSiteData } from 'react-static';
import TagManager from 'react-gtm-module';



const PropTypes = require('prop-types');

const RegisterModal = (props) => {
    const { 
        buttonText, 
        buttonModalText, 
        linkModalText = 'Continue without improved follow-up', 
        buttonClass, 
        href, 
        product, 
        gaProduct, 
        title = 'Let us help you!',
        text = 'For improved follow-up during your trial, please share your work e-mail (Spam Free).',
        mdColImg = 6,
        mdColText = 6

    } = props
    const [showModal, setShowModal] = useState(false);
    const [email, setEmail] = useState('');
    const [asLink, setAsLink] = useState(props.asLink || false);
    const emailRegexp = RegExp(/^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+\.[a-zA-Z0-9-.]+$/);
    const onChangeEmail = e => setEmail(e.target.value);
    const isValidEmail = () => emailRegexp.test(email.trim());
    const toggle = () => setShowModal(!showModal);
    const [openAlert, setOpenAlert] = useState(false);



    const handleSubmit = async () => {
        if (!email.trim()){
            return
        }

        const [error, response] = await to(axios.post(
            `${props.apiUrl}/customers`,
            { 
                gaId: getClientId() || '-', 
                email, 
                gaProduct 
            }, {
                headers: {
                    'sdt-auth-key': props.sdtApiKey
                }
            }));
        if(!error){
            setShowModal(false)
            setOpenAlert(true)
        }
        if(response?.data?.new){
          TagManager.dataLayer({
            dataLayer:{
              'event':'lead',
              'eventCategory':'lead generated',
              'eventAction': gaProduct
            }
          })
        }
    }

    const openModal = () => {
        setShowModal(!showModal)
        // send event to the dataLayer
        TagManager.dataLayer({
            dataLayer:{
              'event':'lead form',
              'eventCategory': 'lead form',
              'eventAction': 'open',
              'eventLabel': gaProduct
            }
        })
    }
    if (openAlert){
        setTimeout(() => {setOpenAlert(false)}, 5000)
    } 
    const closeModal = () => {
        setShowModal(false)
    }
    const closeAlert = () => {
        setOpenAlert(false)
    }

    const getClientId = () => {
        try {
            if (document.cookie.indexOf("_ga=") > -1) {
                const gaCookie = document.cookie.split("_ga=")[1].split(";")[0].split(".");
                const clientID = gaCookie[2] + "." + gaCookie[3];
                return clientID
            }
        } catch (e) {
            console.log("Error al obtener la cookie de GA")
            return null
        }
    }

    return (
        <>
        <Alert className='successAlert' isOpen={openAlert} >
            <i className='fas fa-check icon'/>
             <div className='alertText'>
             You’ve been added to our Beta List. We’ll be in touch soon!
             </div>
            <Button close className={`closeAlert-${product}`} onClick={closeAlert}/>
        </Alert>
        <div className='modal-product'>
            {
                asLink 
                ?<a style={{cursor:'pointer'}} onClick={openModal} >{buttonText}</a>
                :<Button className={buttonClass} onClick={openModal}>{buttonText}</Button>
            }
            <Modal isOpen={showModal} toggle={toggle} size='lg' contentClassName={`modal-size modal-${product}`}>
            <div className={`buttonContainer-${product}`}>
                <Button close className={`closeButton-${product}`} onClick={closeModal}/>
            </div>
                    <ModalBody >
                        <Row className={`modal-background`} />
                        <Row>
                            <Col md={mdColImg} sm={12} xs={12} style={{ display: 'flex', justifyContent: 'center', alignContent: 'center' }}>
                                <img className={`image-${product}`} src={`${props.baseUrlImg}/elements/image-${product}.png`} />
                            </Col>
                            <Col md={mdColText} sm={12} xs={12} style={{ display: 'flex', alignItems: 'center', }}>
                                <div className='modal-right-content'>
                                    <h2 className='modal-title'>{ title }</h2>
                                    <span className='text'>
                                        { text }
                                    </span>
                                    <div className='container-input' >
                                        <input className='input-email' placeholder='work@email.com' onChange={onChangeEmail} type='email' />
                                        <span><img src={`${props.baseUrlImg}/icons/email.png`} /></span>
                                        { email.length > 0 && !isValidEmail() && <p className="input-error">Please enter a valid email</p> }
                                    </div>
                                    <div style={{ textAlign: 'center' }}>
                                        <a target="_blank" rel="noopener noreferrer" href={href}>
                                            <Button style={{ marginTop: '50px', marginBottom: '50px' }} onClick={handleSubmit} className={`button-${product}`}>{buttonModalText}</Button>
                                        </a>
                                        <br />
                                        <a target="_blank" rel="noopener noreferrer" href={href} className="link">
                                            { linkModalText }
                                        </a>
                                    </div>
                                </div>
                            </Col>
                        </Row>
                    </ModalBody>
            </Modal>
        </div>
        </>
    )
}

RegisterModal.propTypes = {
    asLink: PropTypes.bool,
    buttonText: PropTypes.string.isRequired,
    buttonClass: PropTypes.string,
    buttonModalText: PropTypes.string.isRequired,
    product: PropTypes.oneOf(['stand-bot', 'retros', 'fd-trello', 'fs-trello', 'fs-bitbucket', 'meli', 'retros-trello']),
    href: PropTypes.string,
    gaProduct: PropTypes.oneOf(['standbot for slack & jira', 'retrospectives for jira', 'retrospectives for confluence']) //TODO add other products depending on landing using the form
}

export default withSiteData(RegisterModal)