import React from 'react';
import { CardSimple, CardPlan, CardTestimonial } from '../components';


export default (props) => {
  let image = null;

  if(props.image){
    image = (
      <div className="card-image">
        <img src={props.image.src} alt={props.image.alt}/>
      </div>
    );
  }

  return (
    <div className="cards">
      <div className="title bold">{props.title}</div>
      {image}
      <div className="cards-list">
        <div className="row display-flex">
          {
            props.cards.map(
              (card,index) => (
                <div className={props.colClass} key={index}>
                  {component(card.kind, card)}
                </div>
              )
            )
          }
        </div>
      </div>
    </div>
  );
}

const component = (kind, card) => {
  switch (kind) {
    case 'plan':
      return (<CardPlan {...card}/>);
    case 'testimonial':
      return (<CardTestimonial {...card}/>);
    default:
      return (<CardSimple {...card}/>);
  }
}
