import React from 'react';

export default props => (
  <div className='list vertical-top'>
    <div className="row">
      <div className="col-12">
        <div className="title bold">{props.title}</div>
        <div className="bullets">
          <div className="row">
            {
              props.list.map(
                (column, i) => (
                  <div className={column.colClass} key={i}>
                    {
                      column.list.map(
                        (element, j) => (
                          <div className="element" key={j}>
                            <span className="icon"><i className={props.icon} /></span>
                            <span className="text">{element}</span>
                          </div>
                        )
                      )
                    }
                  </div>
                )
              )
            }
          </div>
        </div>
      </div>
      <div className="col-md-12">
        <div className={`img-container ${props.image.imgClass}`}>
          <img src={props.image.src} alt={props.image.alt}/>
        </div>
      </div>
    </div>
  </div>
);