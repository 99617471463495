import Footer from "./footer";
import ListHRight from "./lists/hRight";
import ListVTop from "./lists/vTop";
import ListVTopVideo from "./lists/vTopVideo";
import ListVBottom from "./lists/vBottom";
import ListVSimple from "./lists/vSimple";
import CardSimple from "./cards/simple";
import CardPlan from "./cards/plan";
import CardTestimonial from "./cards/testimonial";

export {
  Footer,
  ListHRight,
  ListVTop,
  ListVTopVideo,
  ListVBottom,
  ListVSimple,
  CardSimple,
  CardPlan,
  CardTestimonial,
}