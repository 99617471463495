import React from 'react';

export default props => (
  <div className='list vertical-bottom'>
    <div className="row">
      <div className="col-12">
        <div className={`title bold ${props.titleClass}`}>{props.title}</div>
        {props.image && (
          <div className="img-container">
            <img src={props.image.src} alt={props.image.alt}/>
          </div>
        )}
      </div>
      <div className="col-12">
        <div className="bullets">
          <div className="row">
            {
              props.list.map(
                (column, i) => (
                  <div className={column.colClass} key={i}>
                    {
                      column.list.map(
                        (element, j) => (
                          <div className="element" key={j}>
                            <span className="icon"><i className={props.icon}></i></span>
                            <span className="text">{element}</span>
                          </div>
                        )
                      )
                    }
                  </div>
                )
              )
            }
          </div>
        </div>
      </div>
    </div>
  </div>
);
