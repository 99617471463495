import React from 'react';
import {
  ListHRight,
  ListVTop,
  ListVTopVideo,
  ListVBottom,
  ListVSimple,
} from '../components';


export default (props) => {
  let list;
  if(props.orientation === 'horizontal'){
    if(props.position === 'right')
      list = (
        <div>
          <div className="d-lg-none">
            <ListVBottom {...props} />
          </div>
          <div className="d-none d-lg-block">
            <ListHRight {...props} />
          </div>
        </div>
      );
  }else{
    if(props.position === 'top') {
      if(props.video) {
        list = <ListVTopVideo {...props} />;
      }
      else
        list = <ListVTop {...props} />;
    } else {
      list = <ListVSimple {...props} />
    }
  }
  return list;
}
