import React from 'react';

export default props => (
  <div className='list horizontal-right'>
    <div className="row">
      {props.image && (
        <div className={props.colClass.left}>
          <img src={props.image.src} alt={props.image.alt}/>
        </div>
      )}
      <div className={props.colClass.right}>
        <div className="title bold">{props.title}</div>
        <div className="bullets">
          <div className="row">
            {
              props.list.map(
                (column, i) => (
                  <div className={column.colClass} key={i}>
                    {
                      column.list.map(
                        (element, j) => (
                          <div className="element" key={j}>
                            <span className="icon"><i className={props.icon}></i></span>
                            <span className="text">{element}</span>
                          </div>
                        )
                      )
                    }
                  </div>
                )
              )
            }
          </div>
        </div>
      </div>
    </div>
  </div>
);
