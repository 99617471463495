import React from 'react';


export default (props) => (
  <div className={`card card-testimonial ${props.classes}`}>
    <div className="user">
      <div className="row">
        <div className="col-md-2">
          <img src={props.image.src} alt={props.image.alt}/>
        </div>
        <div className="col-md-10">
          <div className="name">{props.userName}</div>
          <div className="detail">{props.userDetail}</div>
        </div>
      </div>
    </div>
    <div className="text">
      {props.text}
    </div>
  </div>
)
