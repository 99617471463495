import React from 'react';
import Youtube from 'react-youtube';

export default class Video extends React.Component {
  constructor(props){
    super(props);
    this.state = {};
  }

  render(){
    const opts = {
      height: '100%',
      width: '100%',
      playerVars: {
        autoplay: 1,
        controls: 0,
        loop: 1,
        playlist: this.props.video,
        rel:0
      },
    };

    return (
      <div className="video-container">
        <Youtube
          videoId={this.props.video}
          opts={opts}
        />
      </div>
    );
  }
}