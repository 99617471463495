import React from 'react';

export default (props) => (
  <div className={`card card-simple ${props.classes}`}>
    <div className="row">
      <div className="col-lg-12 col-4 text-left text-lg-center">
        <div>
          <img src={props.image.src} alt={props.image.alt}/>
        </div>
      </div>
      <div className="col-lg-12  col-8 text-left text-lg-center">
        <div className="title bold">{props.title}</div>
        <div className="text">
          {props.text}
        </div>
      </div>
    </div>
  </div>
)
