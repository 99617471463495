import React from "react";
import { withSiteData } from "react-static";
import { List } from "../containers";

export default withSiteData((props) => {
  const year = new Date().getFullYear();
  const toolsList = {
    freshworks: [
      <span className="semi-bold">Freshworks Apps</span>,
      <a href="/freshdesk-trello-powerup/">Freshdesk + Trello</a>,
      <a href="/freshservice/trello-app/">Freshservice + Trello</a>,
    ],
    atlassian: [
      <span className="semi-bold">Atlassian Apps</span>,
      <a href="/agile-retrospectives-in-jira-confluence">
        Agile Retrospectives
      </a>,
      <a href="/scrum-poker">Scrum Poker for Confluence</a>,
      <a href="/scrum-poker/jira">Scrum Poker for Jira</a>,
      <a href="/stand-bot/">Stand-Bot</a>,
    ],
  };

  const getInTouch = [
    <a href="https://planningpoker.atlassian.net/servicedesk/customer/portal/2">
      Support
    </a>,
    <a href="https://blog.catapultlabs.com">Blog</a>,
    ...(props.getInTouch || []),
  ];

  return (
    <div className="footer">
      {/* mobile version (a complete block due to components position) */}
      <div className={`d-block d-lg-none ${props?.colClass?.sm_footer}`}>
        <div className={`row ${props.colClass?.tools}`}>
          <div
            className={`${
              props.colClass?.sm_tools ? props?.colClass?.sm_tools : "col-12"
            }`}
          >
            <div className="tools">
              <List
                title="Tools"
                list={[
                  {
                    colClass: "col-6 left",
                    list: props.toolsList?.freshworks
                      ? props.toolsList.freshworks
                      : toolsList.freshworks,
                  },
                  {
                    colClass: "col-6 right",
                    list: props.toolsList?.atlassian
                      ? props.toolsList.atlassian
                      : toolsList.atlassian,
                  },
                ]}
              />
            </div>
          </div>
          <div className="col-4">
            <div className="get-in-touch">
              <List
                title="Get in Touch"
                list={[
                  {
                    colClass: "col-md-12",
                    list: getInTouch,
                  },
                ]}
              />
            </div>
          </div>
        </div>
        <div className={`row ${props?.colClass?.sm_logos}`}>
          <div className="col-4">
            <div className="logos">
              <div className="logo">
                <a target="_blank" rel="noopener noreferrer" href="/">
                  <img
                    src={`${props.baseUrlImg}/logos/softwaredevtools-white.png`}
                    alt="logo softwaredevtools white"
                  />
                </a>
              </div>
              <div className="social">
                <div className="logo">
                  <a
                    target="_blank"
                    rel="noopener noreferrer"
                    href="https://www.linkedin.com/company/softwaredevtools/"
                  >
                    <img
                      src={`${props.baseUrlImg}/logos/linkedin-white.png`}
                      alt="logo linkedin white"
                    />
                  </a>
                </div>
                <div className="logo">
                  <a
                    target="_blank"
                    rel="noopener noreferrer"
                    href="https://twitter.com/catapultlabs_"
                  >
                    <img
                      src={`${props.baseUrlImg}/logos/twitter-white.png`}
                      alt="logo twitter white"
                    />
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* web version (a complete block due to components position) */}
      <div className="d-none d-lg-block">
        <div className="row">
          <div className="col-lg-3">
            <div className="logos">
              <div className="logo">
                <a target="_blank" rel="noopener noreferrer" href="/">
                  <img
                    src={`${props.baseUrlImg}/logos/catapult-labs-white.png`}
                    alt="logo catapult labs white"
                  />
                </a>
              </div>
              <div className="social">
                <div className="logo">
                  <a
                    target="_blank"
                    rel="noopener noreferrer"
                    href="https://www.linkedin.com/company/softwaredevtools/"
                  >
                    <img
                      src={`${props.baseUrlImg}/logos/linkedin-white.png`}
                      alt="logo linkedin white"
                    />
                  </a>
                </div>
                <div className="logo">
                  <a
                    target="_blank"
                    rel="noopener noreferrer"
                    href="https://twitter.com/catapultlabs_"
                  >
                    <img
                      src={`${props.baseUrlImg}/logos/twitter-white.png`}
                      alt="logo twitter white"
                    />
                  </a>
                </div>
              </div>
            </div>
          </div>
          <div className="col-lg-9">
            <div className="row">
              <div className="col-lg-7 tools">
                <List
                  title="Tools"
                  list={[
                    {
                      colClass: "col-lg-6 left",
                      list: props.toolsList?.freshworks
                        ? props.toolsList.freshworks
                        : toolsList.freshworks,
                    },
                    {
                      colClass: "col-lg-6 right",
                      list: props.toolsList?.atlassian
                        ? props.toolsList.atlassian
                        : toolsList.atlassian,
                    },
                  ]}
                />
              </div>
              <div className="col-lg-3 get-in-touch">
                <List
                  title="Get in Touch"
                  list={[
                    {
                      colClass: "col-lg-12",
                      list: getInTouch,
                    },
                  ]}
                />
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className={`row ${props?.colClass?.terms}`}>
        <div className="col-lg-3 col-12">
          <div className="note">
            © {year} Catapult Labs. All Rights Reserved
          </div>
        </div>
        <div className="col-lg-9 col-12">
          <div
            className={`legal text-left ${props?.colClass?.legal_container}`}
          >
            <span>
              <a
                target="_blank"
                rel="noopener noreferrer"
                className="gold"
                href="https://www.catapultlabs.com/legal"
              >
                Terms and Conditions
              </a>
            </span>
            <span className="separator gold">|</span>
            <span>
              <a
                target="_blank"
                rel="noopener noreferrer"
                className="gold"
                href="https://www.catapultlabs.com/legal"
              >
                Privacy Policy
              </a>
            </span>
            <span className="separator gold">|</span>
            <span>
              <a
                target="_blank"
                rel="noopener noreferrer"
                className="gold"
                href="https://www.catapultlabs.com/legal"
              >
                EULA
              </a>
            </span>
            <span className="separator gold">|</span>
            <span>
              <a
                target="_blank"
                rel="noopener noreferrer"
                className="gold"
                href="https://www.catapultlabs.com/legal"
              >
                SLA
              </a>
            </span>
          </div>
        </div>
      </div>
    </div>
  );
});
