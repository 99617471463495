import React from 'react';
import { Button } from 'reactstrap';
import { List } from '../../containers';
import RegisterModal from '../registerModal';


export default (props) => {
  let priceLink = null;
  let screenshot = null;

  if(props.price.link){
    //remove this ternay operator when all landings use the RegisterModal component
    priceLink = React.isValidElement(props.price.link)
      ?
        props.price.link
      : (
        <a
          target="_blank"
          rel="noopener noreferrer"
          href={props.price.link.href}
          title={props.price.link.title}
        >
          {props.price.link.text}
        </a>
      );
  }
  if(props.imagePremium){
    screenshot = (
      <div className="image">
        <img className="premium-icon" src={props.imagePremium.src} alt={props.imagePremium.alt}/>
        <img src={props.image.src} alt={props.image.alt}/>
      </div>
    );
  }else{
    screenshot = (
      <div className="image">
        <img src={props.image.src} alt={props.image.alt}/>
      </div>
    );
  }

  return (
  <div className={`card card-plan ${props.classes}`}>
    <div className="logos">
      <div className="row">
        {
          props.logos.map(
            (logo, index) => (
              <div className={logo.colClass} key={index}>
                <div className="logo">
                  <img src={logo.src} alt={logo.alt}/>
                </div>
              </div>
            )
          )
        }
      </div>
    </div>
    <div className="title">
      {props.title}
    </div>
    <div className="price">
      <div className="description">
        <div className="title">{props.price.title}</div>
        <div className="number">{props.price.number}</div>
        <div className="text">{props.price.text}</div>
      </div>
      <div className="link">
        {priceLink}
      </div>
    </div>
    {screenshot}
    <div className="button">
      {
        //remove this ternary operator when all landings use the RegisterModal component
        React.isValidElement(props.button)
        ?
        props.button
        :
        <a target="_blank" rel="noopener noreferrer" href={props.button.url}>
          <Button className={props.button.btnClass}>{props.button.text}</Button>
        </a>
      }
    </div>
    <List
      icon="fas fa-check"
      {...props.list}
    />
    <div className="foot-note italic">
      {props.footNote}
    </div>
  </div>
)}
